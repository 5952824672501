<template>
  <div class="">
    <button
      @click="openCartModal"
      class="text-white flex items-center space-x-2 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      type="button">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
      </svg>
      <span>{{ $t('Cart') }} ({{ cart.length }} {{ $t('Items')}}) - {{ $t('Total') }} {{ cartTotal | germanNumberFormat }}</span>
    </button>
    <vx-modal @close="closeCartModal()" v-if="showCartModal"
              component-class="modal-container-fit">
      <template slot="body">
        <div>

          <div class="flex flex-col items-start space-y-4">

            <div class="flex flex-row justify-between items-center w-full">
              <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {{ $t('Cart') }}
              </h5>
              <vs-spacer></vs-spacer>
              <vs-button @click="closeCartModal()" class="mx-2" size="normal" color="danger"
                         type="filled"
                         icon-pack="feather" icon="icon-x"></vs-button>
            </div>

            <div class="flex flex-row w-full space-x-4" v-for="(item, index) of cart">
              <!-- Cart Products List -->
              <div class="flex flex-col" >
                <div class="p-image-holder c-center">
                  <img class="p-image max-w-[100px] max-h-[100px]"
                       :src="item.product.image" alt="">
                </div>
              </div>
              <div class="flex flex-col space-y-3" >
                <h5 class="text-xl font-bold">
                  {{ item.product.name }}
                </h5>
                <h5 class=" font-bold">
                  <span class="text-success">{{ item.product.price | germanNumberFormat }}</span> x {{ item.quantity }}
                </h5>
              </div>
            </div>

            <hr>
            <div class="flex flex-row w-full font-bold justify-between items-center">
              <h5>
                {{ $t('Total') }}
              </h5>
              <h5 class="text-success">
                {{ cartTotal | germanNumberFormat }}
              </h5>
            </div>

            <hr>
          </div>


          <div class="flex flex-wrap justify-center mb-3 py-4">
            <vs-button class="mx-3" color="danger" type="border" @click="clearCart()">
              {{ $t('ClearCart') }}
            </vs-button>
            <vs-button class="mx-3" color="danger" type="border" @click="closeCartModal()">
              {{ $t('Cancel') }}
            </vs-button>
            <vs-button class="mx-3" @click="buyProducts()">{{ $t('Buy') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: "HardwareCartComponent",
  components: {VxModal},
  data() {
    return {
      showCartModal: false,
    }
  },
  computed: {
    cart() {
      return this.$store.state.hardwareSeller.cart;
    },
    cartTotal() {
      if(this.cart.length > 0) {
        return this.cart.reduce((acc, item) => {
          return acc + (item.product.price * item.quantity);
        }, 0);
      }
      return 0;
    },
    cartTotalItems() {
      if(this.cart.length > 0) {
        return this.cart.reduce((acc, item) => {
          return acc + item.quantity;
        }, 0);
      }
      return 0;
    },
  },
  methods: {
    openCartModal() {
      this.showCartModal = true
    },
    clearCart() {
      this.$store.commit('hardwareSeller/CLEAR_CART');
      this.showCartModal = false
    },
    closeCartModal() {
      this.showCartModal = false
    },
    buyProducts() {
      let data = [];
      this.cart.forEach(item => {
        data.push({
          product_id: item.product.id,
          quantity: item.quantity,
        });
      });
      this.closeCartModal();
      this.$vs.loading()
      this.$store.dispatch('hardwareSeller/createHardwareOrder', {
        products_data: JSON.stringify(data),
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: 'Order placed successfully',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
          });
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
}
</script>

<style scoped>

</style>
