<template>
  <div class="flex flex-col">
    <div id="hardware-categories" class="hardware-categories-container">

      <!-- BREADCRUMB -->
      <breadcrumb-base :breadcrumb="breadcrumb" :title="'Hardware'">
        <template v-slot:right-end>
          <div class="vx-row">
            <div class="p-3"></div>
            <hardware-cart-component/>

          </div>
        </template>
      </breadcrumb-base>

      <!--    Empty List State -->
      <transition name="fade">
        <div class="py-6 flex w-full bg-img" v-if="products.length === 0 && !errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
            <!--               class="mx-auto mb-4 max-w-full">-->
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
          </div>
        </div>
      </transition>

      <!--    Error List State-->
      <transition name="fade">
        <div class="py-6 flex w-full bg-img" v-if="errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                 class="mx-auto mb-4 max-w-full">
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="flex flex-wrap gap-x-6 gap-y-6">
          <a
            v-for="(product, index) of products"
            @click="openProductPage(product)"
            class="flex cursor-pointer flex-col space-y-2 justify-between p-4 min-w-xs max-w-xs bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <img :src="product.image" alt="product"
                 class="w-full">
            <vs-spacer></vs-spacer>
            <div class="flex flex-row justify-between space-x-2">
              <p class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {{ product.name }}
              </p>
              <p
                class="text-2xl text-success font-bold tracking-tight text-gray-900 dark:text-white">
                {{ product.price | germanNumberFormat }}
              </p>
            </div>
            <p class="tracking-tight text-gray-900 dark:text-white">
              {{ product.description }}
            </p>
          </a>

        </div>
      </transition>

      <vx-modal @close="closeProductPage()" v-if="showProductModal"
                component-class="modal-container-fit">
        <template slot="body">
          <div>

            <div class="flex flex-row justify-center items-start space-x-4">
              <div class="py-4 flex justify-center relative items-center" style="text-align: center;"
                   v-if="selectedProduct">
                <div class="p-image-holder c-center">
                  <img class="p-image max-w-[320px]"
                       :src="selectedProduct.image" alt="">
                </div>
              </div>
              <div class="flex flex-col space-y-4 max-w-xl">
                <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  {{ selectedProduct.name }}
                </h5>
                <h5
                  class="text-2xl text-success font-bold tracking-tight text-gray-900 dark:text-white">
                  {{ selectedProduct.price | germanNumberFormat }}
                </h5>
                <h5 class="tracking-tight text-gray-900 dark:text-white">
                  {{ selectedProduct.description }}
                </h5>
                <br>

                <div class="flex flex-row">
                  <vs-button @click="removeQuantity()" class="mx-2" size="normal" color="danger"
                             type="filled"
                             icon-pack="feather" icon="icon-minus"></vs-button>
                  <!--                 <h4 class="item-card-amount">{{ adultCount }}</h4>-->
                  <div class="flix-input-holder mx-1">
                    <div class="">
                      <input v-model="productQuantity"
                             type="text"
                             placeholder="0" class="flix-input-field"
                      >
                    </div>
                  </div>
                  <vs-button @click="addQuantity()" class="mx-2" size="normal" color="success"
                             type="filled"
                             icon-pack="feather" icon="icon-plus"></vs-button>
                </div>
                <div class="flex flex-row font-bold text-xl">
                  <p>{{ $t('TotalSum') }} : {{ selectedProduct.price * productQuantity | germanNumberFormat }}</p>
                </div>
              </div>
            </div>


            <div class="flex flex-wrap justify-center mb-3 py-4">
              <vs-button class="mx-3" color="danger" type="border" @click="closeProductPage()">
                {{ $t('Cancel') }}
              </vs-button>
              <vs-button class="mx-3" @click="addProductToCart()">{{ $t('AddProduct') }}</vs-button>
            </div>
          </div>
        </template>
      </vx-modal>


    </div>
    <div style="position: absolute; bottom: 5px;">
      <p style="font-size: 8px;">* Zuzüglich Postgebühren und Steuer</p>
    </div>
  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";
import HardwareCartComponent from "@/views/pages/sellers/hardware/HardwareCartComponent";

export default {
  name: "HardwareProducts",
  components: {HardwareCartComponent, VxModal},
  props: {
    categoryId: {
      type: String | Number,
    },
  },
  data() {
    return {
      errorFetching: false,
      showProductModal: false,
      showCartModal: false,
      selectedProduct: null,
      productQuantity: 1,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Hardware', i18n: 'Categories', url: '/hardware/categories'},
        {title: 'Hardware', i18n: 'Products', active: true},
      ],
    }
  },
  computed: {
    products() {
      return this.$store.state.hardwareSeller.products;
    },
    cart() {
      return this.$store.state.hardwareSeller.cart;
    },
    cartTotal() {
      if(this.cart.length > 0) {
        return this.cart.reduce((acc, item) => {
          return acc + (item.product.price * item.quantity);
        }, 0);
      }
      return 0;
    },
  },
  methods: {
    openCartModal() {
      this.showCartModal = true
    },
    closeCartModal() {
      this.showCartModal = false
    },
    openProductPage(product) {
      this.selectedProduct = product;
      this.showProductModal = true
    },
    closeProductPage() {
      this.selectedProduct = null;
      this.productQuantity = 1;
      this.showProductModal = false
    },
    addQuantity() {
      this.productQuantity++;
    },
    removeQuantity() {
      if (this.productQuantity > 1) {
        this.productQuantity--;
      }
    },
    addProductToCart() {
      if (this.productQuantity >= 1) {
        this.$store.commit('hardwareSeller/SET_CART_PRODUCT', {
          product: this.selectedProduct,
          id: this.selectedProduct.id,
          quantity: this.productQuantity,
        });
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: 'Product added to cart',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check',
        });
        this.closeProductPage();
      }
    },
    buyProducts() {
      let data = [];
      this.cart.forEach(item => {
        data.push({
          product_id: item.product.id,
          quantity: item.quantity,
        });
      });
      this.closeCartModal();
      this.$vs.loading()
      this.$store.dispatch('hardwareSeller/createHardwareOrder', {
        products_data: JSON.stringify(data),
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: 'Order placed successfully',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
          });
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCategoryProducts() {
      this.$vs.loading()
      this.$store.dispatch('hardwareSeller/fetchHardwareCategoryProducts', {
        category_id: this.categoryId,
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchCategoryProducts();
  },
}
</script>

<style lang="scss">

.flix-input-holder {
  width: 48px;
  padding: 0;
  margin: 0;
}

.flix-input-field {
  height: 40px;
  border: 1px solid #c8c8c8;
  text-align: center;
  width: 100%;
  text-indent: 0;
  border-radius: 5px;
  font-size: 24px;
}
.hardware-categories-container {
  //padding-top: 48px;
}
</style>
